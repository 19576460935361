import { render, staticRenderFns } from "./Pato-Branco.vue?vue&type=template&id=676f03fc&scoped=true&"
import script from "./Pato-Branco.vue?vue&type=script&lang=js&"
export * from "./Pato-Branco.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "676f03fc",
  null
  
)

export default component.exports